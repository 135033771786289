<template>
  <multiselect
    :value="value"
    ref="select"
    :searchable="true"
    :internal-search="false"
    :preserve-search="true"
    :clearOnSelect="false"
    :show-no-options="true"
    :show-no-results="true"
    :hide-selected="false"
    :options="surnameOptions"
    track-by="name_ch"
    label="name_ch"
    openDirection="bottom"
    selectLabel=""
    selectedLabel=""
    deselectLabel=""
    :placeholder="placeholder"
    :loading="loading"
    class="bordered"
    name="clan-name"
    @open="onOpen"
    @search-change="onSurnameSearchChange"
    @select="onClanSelect"
    @close="onClose"
  >
    <template v-slot:caret>
      <div
        class="caret-icon close-icon"
        v-if="value && !isMultiselectOpened()"
        @mousedown.prevent.stop="clearSurname()"
      >
        <close-icon></close-icon>
      </div>
    </template>
    <span slot="noResult" class="input-helper">No elements found.</span>
    <span slot="noOptions" class="input-helper">Start typing to search...</span>
    <template v-slot:option="params">
      <span class="chinese-name">{{ getChineseNameDisplay(params.option) }}</span>
      <span class="english-name text-sm">{{ getEnglishNameDisplay(params.option) }}</span>
    </template>
    <template v-slot:singleLabel>
      <template v-if="value">
        <span class="chinese-name">{{ value }}</span>
      </template>
      <span v-else class="placeholder">{{ placeholder }}</span>
    </template>
    <div slot="afterList" class="loading-after-list" v-if="loading">
      <span class="input-helper">Searching...</span>
    </div>
  </multiselect>
</template>

<script>
import {isChineseText} from '@common/utils/utils';
import debounce from 'lodash/debounce';
import upperFirst from 'lodash/upperFirst';
import CloseIcon from 'vue-material-design-icons/Close';
import Multiselect from 'vue-multiselect';
import {mapGetters} from 'vuex';

export default {
  props: {
    placeholder: String,
    value: String,
    initSearchValue: String,
    hasError: Boolean,
  },
  data() {
    return {
      searchQuery: '',
      loadingByQuery: {},
    };
  },
  computed: {
    ...mapGetters(['clansSearchState']),
    surnameOptions() {
      const options = this.clansSearchState;
      if (this.searchQuery && !isChineseText(this.searchQuery)) {
        const capitalizedQuery = upperFirst(this.searchQuery);
        return [{name_ch: capitalizedQuery}, ...options];
      }
      return options;
    },
    loading() {
      return Object.values(this.loadingByQuery).some(isLoading => !!isLoading);
    },
  },
  methods: {
    onOpen() {
      if (this.value) {
        return this.$refs.select.updateSearch(this.value);
      }
      if (this.initSearchValue) {
        return this.surnameSearch(this.initSearchValue);
      }
    },
    onClose(value, id) {
      const search = this.$refs.select.search.trim();
      this.onSurnameChange(search);
    },
    getChineseNameDisplay(option) {
      if (option.name_hant && option.name_ch !== option.name_hant) {
        return `${option.name_ch} / ${option.name_hant}`;
      }
      return option.name_ch;
    },
    getEnglishNameDisplay(option) {
      let namesList = [option.pinyin];
      const pinyinLower = option.pinyin && option.pinyin.toLowerCase();
      const aliasLower = option.alias && option.alias.toLowerCase();
      if (
        pinyinLower &&
        aliasLower &&
        pinyinLower !== aliasLower &&
        option.name_ch !== aliasLower &&
        option.name_hant !== aliasLower
      ) {
        namesList.push(option.alias);
      }
      const names = namesList.join(' / ');
      return names ? names : '';
    },
    onClanSelect(option) {
      const value = option && option.name_ch ? option.name_ch.trim() : '';
      this.$refs.select.updateSearch(value);
    },
    clearSurname() {
      this.$refs.select.updateSearch('');
      this.$emit('change', null);
    },
    onSurnameChange(value) {
      this.$emit('change', value);
    },
    onSurnameSearchChange(query) {
      this.searchQuery = query;
      this.$store.commit('setClansSearchState', []);
      if (!query) {
        return;
      }
      this.loadingByQuery = {[query]: true};
      this.surnameSearch(query);
    },
    surnameSearch: debounce(function (query) {
      this.$store.dispatch('searchClansAction', {q: query, limit: 20}).finally(() => {
        this.loadingByQuery[query] = false;
      });
    }, 200),
    isMultiselectOpened() {
      return this.$refs.select && this.$refs.select.isOpen;
    },
  },
  components: {Multiselect, CloseIcon},
  name: 'SurnameAutocompleteSelect',
};
</script>

<style lang="scss" scoped>
.multiselect::v-deep {
  .multiselect__option {
    .english-name {
      color: $neutral-400;
      margin-left: 5px;
    }
  }
  .multiselect__option.multiselect__option--highlight {
    .english-name {
      color: white;
    }
  }
}
</style>
